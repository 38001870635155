<template>
  <van-action-sheet
    class="reason-sheet"
    v-model:show="isShowAfterSaleType"
    :title="t('afterSale.typeSheetTitle')"
    @close="onClose"
    teleport="body"
    :closeable="true"
    :cancel-text="t('common.cancel')"
    :close-on-popstate="true"
    :safe-area-inset-bottom="true"
    z-index="9999"
  >
    <div class="sheet-content">
      <van-radio-group v-model="reasonCode" v-if="dataList">
        <van-cell-group>
          <van-cell
            center
            size="large"
            :title="item.name"
            clickable
            @click="onItemClick(item)"
            v-for="item in dataList"
          >
            <template #right-icon>
              <div class="text">
                <div class="note">{{ item.note }}</div>
                <div class="valid-date" v-if="item?.validDate">{{ item?.validDate }}</div>
              </div>
              <!-- <NewkiSvg name="chevron-right" /> -->
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
  </van-action-sheet>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { getGoodAfterSaleType, getOrderAfterSaleType } from '~/service/api/order'

import { useAfterSaleStore } from '~/store/afterSale'
const { setSkuId, setAfterSaleType, setCurrentOrderId } = useAfterSaleStore()
const { reasonList, skuId, isShowAfterSaleType, currentOrderId } = storeToRefs(useAfterSaleStore())
const { t } = useI18n()

const getData = async () => {
  let orderId = props.orderId ? props.orderId : currentOrderId.value
  let data = await getGoodAfterSaleType(orderId as string)
  if (data) {
    console.log('dataList:', data)
    dataList.value = data
  }
}

watch(
  isShowAfterSaleType,
  val => {
    if (val) {
      // console.log("isShowAfterSaleType:", isShowAfterSaleType.value);
      // console.log("skuId:", skuId.value);
      // getData();
    }
  },
  { immediate: true, deep: true }
)

const props = defineProps({
  orderId: {
    type: String,
    required: false,
    default: '',
  },
})

const onClose = () => {
  // console.log("onClose");
  setAfterSaleType(false)
  setCurrentOrderId('')
  setSkuId('')
}

const reasonCode = ref('')

const dataList: any = ref(reasonList)

const onItemClick = (item: any) => {
  // reasonCode.value = item.code;

  let orderId = props.orderId ? props.orderId : currentOrderId.value
  // console.log(orderId)
  routerPush('afterSale-apply-id', {
    query: {
      orderId: orderId,
      type: item.code,
      typeText: item.name,
      skuId: skuId.value,
    },
    params: {
      id: 0,
    },
  })

  setAfterSaleType(false)
  setCurrentOrderId('')
  setSkuId(skuId.value)
}
</script>

<style scoped lang="scss">
.sheet-content {
  min-height: 56px;
}

.text {
  @include flex(column, center, flex-end);
  @extend .text-tips;
  .note {
    color: $text-color-assist;
  }
  .valid-date {
    color: $danger-text;
  }
}
</style>
